import logo from './logo.svg';
import './App.css';
import { useEffect, useRef, useState } from 'react';


const panels = [
  '/assets/Communaux$Entrée_dans_une_agglomération.png',
  '/assets/Communaux$Entrée_d_un_itinéraire_touristique.png',
  '/assets/Communaux$Lieu_dit_forêt_ou_autre_lieu_traversé_par_une_route.png',
  '/assets/Communaux$Sortie_d_une_agglomération.png',
  '/assets/Communaux$Sortie_d_un_lieu_dit.png',
  '/assets/Dangers$Annonce_feux_tricolores.png',
  '/assets/Dangers$Arrêt_à_l_intersection.png',
  '/assets/Dangers$Arrêt_à_l_intersection_à_150m.png',
  '/assets/Dangers$Barrières_à_fonctionnement_manuel.png',
  '/assets/Dangers$Carrefour_à_sens_giratoire_avec_priorité_aux_usagers_circulant_sur_l_anneau.png',
  '/assets/Dangers$Cassis_ou_dos_d_âne.png',
  '/assets/Dangers$Chaussée_rétrécie.png',
  '/assets/Dangers$Chaussée_rétrécie_par_la_droite.png',
  '/assets/Dangers$Chaussée_rétrécie_par_la_gauche.png',
  '/assets/Dangers$Chaussé_particulièrement_glissante.png',
  '/assets/Dangers$Circulation_dans_les_deux_sens.png',
  '/assets/Dangers$Cédez_le_passage_aux_véhicules_arrivant_à_votre_droite.png',
  '/assets/Dangers$Cédez_le_passage_à_l_intersection.png',
  '/assets/Dangers$Cédez_le_passage_à_l_intersection_à_150m.png',
  '/assets/Dangers$Danger_permanent.png',
  '/assets/Dangers$Danger_pouvant_être_précisé_ou_non_par_un_panonceau.png',
  '/assets/Dangers$Danger_temporaire.png',
  '/assets/Dangers$Descente_dangereuse.png',
  '/assets/Dangers$Débouché_de_cyclistes_venant_de_droite_ou_de_gauche.png',
  '/assets/Dangers$Débouché_sur_un_quai_ou_une_berge.png',
  '/assets/Dangers$Endroit_fréquenté_par_les_enfants.png',
  '/assets/Dangers$Fin_de_route_prioritaire.png',
  '/assets/Dangers$Intersection_où_vous_êtes_prioritaire.png',
  '/assets/Dangers$Passage_de_cavaliers.png',
  '/assets/Dangers$Passage_d_animaux_domestiques.png',
  '/assets/Dangers$Passage_d_animaux_sauvages.png',
  '/assets/Dangers$Passage_pour_piétons.png',
  '/assets/Dangers$Passage_à_niveau_sans_barrière_ni_demi_barrière.png',
  '/assets/Dangers$Pont_mobile.png',
  '/assets/Dangers$Ralentisseur.png',
  '/assets/Dangers$Risque_de_chut_de_pierres.png',
  '/assets/Dangers$Risque_de_fort_vent_latéral.png',
  '/assets/Dangers$Route_prioritaire.png',
  '/assets/Dangers$Succession_de_virages_dont_le_premier_est_à_droite.png',
  '/assets/Dangers$Succession_de_virages_dont_le_premier_est_à_gauche.png',
  '/assets/Dangers$Traversée_de_voies_de_tramways.png',
  '/assets/Dangers$Traversée_de_voies_de_transport_en_commun.png',
  '/assets/Dangers$Traversée_d_une_aire_de_danger_aérien.png',
  '/assets/Dangers$Virage_à_droite.png',
  '/assets/Dangers$Virage_à_gauche.png',
  '/assets/Directions$Itinéraires_secondaires_ainsi_que_les_localité_les_plus_proches.png',
  '/assets/Directions$Itinéraire_empruntant_une_autoroute.png',
  '/assets/Directions$Itinéraire_principal_reliant_de_grandes_agglomérations_importantes.png',
  '/assets/Indication$Annonce_d_un_créneau_de_dépassement_ou_d_une_route_2x2_voies.png',
  '/assets/Indication$Chaussée_à_double_sens_Sens_opposé_réservé_aux_bus.png',
  '/assets/Indication$Chaussée_à_double_sens_Sens_opposé_réservé_aux_cycles.png',
  '/assets/Indication$Circulation_à_sens_unique.png',
  '/assets/Indication$Conditions_particulières_de_circulation_sur_la_route_ou_la_voie_embranchée.png',
  '/assets/Indication$Conditions_particulières_de_circulation_sur_la_voie_centrale.png',
  '/assets/Indication$Conditions_particulières_de_circulation_trois_voies_de_circulation_dans_ce_sens.png',
  '/assets/Indication$Condition_particulières_de_circulation_par_voie_en_terme_de_limitation_de_vitesse.png',
  '/assets/Indication$Créneau_de_j_dépassement_sur_section_de_route_à_trois_voies.png',
  '/assets/Indication$Début_de_voie_verte.png',
  '/assets/Indication$Début_d_une_section_d_autoroute.png',
  '/assets/Indication$Emplacement_d_arrêt_St_d_urgence.png',
  '/assets/Indication$Entrée_d_aire_piétonne.png',
  '/assets/Indication$Entrée_d_un_tunnel.png',
  '/assets/Indication$Entrée_zone_de_rencontre.png',
  '/assets/Indication$Fin_de_créneau_de_dépassement.png',
  '/assets/Indication$Fin_de_piste_ou_bande_cyclabli_conseillée_et_réservée_aux_cycles_à_2_où_3_roues.png',
  '/assets/Indication$Fin_de_route_à_accès_réglementé.png',
  '/assets/Indication$Fin_de_section_à_vitesse_régulée.png',
  '/assets/Indication$Fin_de_vitesse_conseilée.png',
  '/assets/Indication$Fin_de_voie_verte.png',
  '/assets/Indication$Fin_d_une_section_d_autoroute.png',
  '/assets/Indication$Impasse_comportant_une_issue_pour_les_cyclistes.png',
  '/assets/Indication$Impasse_comportant_une_issue_pour_les_piétons.png',
  '/assets/Indication$Indications_diverses.png',
  '/assets/Indication$Indication_sur_autoroute.png',
  '/assets/Indication$La_voie_de_circulation_en_sens_inverse_est_réservée_aux_cyclistes.png',
  '/assets/Indication$Lieu_aménagé_pour_le_stationnement_le_parking_lir.png',
  '/assets/Indication$Lieu_aménagé_pour_stationnement_gratuit_à_duré_nitée_avec_contrôle_par_disqu.png',
  '/assets/Indication$Paiement_auprès_d_un_péagiste.png',
  '/assets/Indication$Paiement_automatique_par_pièces_de_monnaie.png',
  '/assets/Indication$Paiement_automatique_par_pièces_et_billets.png',
  '/assets/Indication$Paiement_par_abonnement.png',
  '/assets/Indication$Paiement_par_carte_bancaire.png',
  '/assets/Indication$Paiement_par_carte_bancaire_ou_accréditive.png',
  '/assets/Indication$Panneau_Voie_sans_issue.png',
  '/assets/Indication$Passage_pour_piétons.png',
  '/assets/Indication$Piste_ou_bande_cyclable_1_conseillée_et_réservée_aux_cycles_à_2_ou_3_roues.png',
  '/assets/Indication$Priorité_par_rapport_à_la_circulation_venant_en_sens_inverse.png',
  '/assets/Indication$Présignalisation_d_une_impasse.png',
  '/assets/Indication$Présignalisation_du_début_d_une_section_à_vitesse_régulée.png',
  '/assets/Indication$Présignalisation_d_une_borne_de_retrait_de_ticket_de_péage.png',
  '/assets/Indication$Ralentisseur_1.png',
  '/assets/Indication$Traversée_de_voie_de_véhicule_routiers_des_services_réguliers_de_transport_en_commun.png',
  '/assets/Indication$Route_à_accès_réglementé.png',
  '/assets/Indication$Réduction_du_nombre_de_voies.png',
  '/assets/Indication$Section_de_route_à_trois_voies_affectées.png',
  '/assets/Indication$Signalisation_par_voie.png',
  '/assets/Indication$Sortie_de_tunnel.png',
  '/assets/Indication$Sortie_de_zone_de_rencontre.png',
  '/assets/Indication$Sortie_d_aire_piétonne.png',
  '/assets/Indication$Stationnement_e_payant_e.png',
  '/assets/Indication$Stationnement_réglementé_pour_les_caravanes_et_les_autocaravanes.png',
  '/assets/Indication$Station_de_taxis.png',
  '/assets/Indication$Station_d_arrêt_d_autobus.png',
  '/assets/Indication$Station_pour_véhicule_bénéficia_de_label_Autopartage.png',
  '/assets/Indication$Traversée_de_voie_de_tramways.png',
  '/assets/Indication$Vitesse_conseillée.png',
  '/assets/Indication$Voies_affectées.png',
  '/assets/Indication$Voie_de_détresse.png',
  '/assets/Interdictions$Acces_interdit_aux_véhicules_dont_la_hauteur_chargement_compris_dépasse_la_dimension_indiquée.png',
  '/assets/Interdictions$Acces_interdit_aux_véhicules_dont_la_largeur_chargement_compris_dépasse_la_dimension_indiquée.png',
  '/assets/Interdictions$Acces_interdit_aux_véhicules_dont_la_longueur_chargement_compris_dépasse_la_dimension_indiquée.png',
  '/assets/Interdictions$Accès_interdit_aux_cycles.png',
  '/assets/Interdictions$Accès_interdit_aux_cyclomoteurs.png',
  '/assets/Interdictions$Accès_interdit_aux_motos_et_motos_légères.png',
  '/assets/Interdictions$Accès_interdit_aux_piétons.png',
  '/assets/Interdictions$Accès_interdit_aux_voitures_à_bras.png',
  '/assets/Interdictions$Accès_interdit_aux_véhicules_agricoles_à_moteur.png',
  '/assets/Interdictions$Accès_interdit_aux_véhicules_de_transport_de_marchandises_explosives_ou_facilement_inflammables.png',
  '/assets/Interdictions$Accès_interdit_aux_véhicules_de_transport_en_commun.png',
  '/assets/Interdictions$Accès_interdit_aux_véhicules_tractant_une_caravane_ou_remorque_de_plus_de_250kg.png',
  '/assets/Interdictions$Accès_interdit_aux_véhicules_transportant_des_marchandises.png',
  '/assets/Interdictions$Accès_interdit_aux_véhicules_transportant_des_marchandises_dangereuses.png',
  '/assets/Interdictions$Accès_interdit_aux_véhicules_transportant_des_marchandises_polluant_l_eau.png',
  '/assets/Interdictions$Accès_interdit_aux_véhicules_à_moteur.png',
  '/assets/Interdictions$Accès_interdit_aux_véhicules_à_moteur_sauf_cyclomoteurs.png',
  '/assets/Interdictions$Accès_interdit_aux_véhicules_à_traction_animale.png',
  '/assets/Interdictions$Arrêt_et_stationnemen_interdits.png',
  '/assets/Interdictions$Arrêt_obligatoire_au_barrage_de_gendarmerie.png',
  '/assets/Interdictions$Arrêt_obligatoire_au_barrage_de_police.png',
  '/assets/Interdictions$Arrêt_obligatoire_au_poste_de_douane.png',
  '/assets/Interdictions$Circulation_interdite_à_tout_véhicule_dans_les_deux_sens.png',
  '/assets/Interdictions$Cédez_le_passage_à_la_circulation_venant_en_sens_inverse.png',
  '/assets/Interdictions$Entrée_de_zone_de_circulation_restreinte.png',
  '/assets/Interdictions$Interdiction_de_circuler_sans_maintenir_l_invervalle_indiqué_.png',
  '/assets/Interdictions$Interdiction_de_dépasser_pour_les_transports_de_marchandises_à_3_5t.png',
  '/assets/Interdictions$Interdiction_de_dépasser_tout_véhicule_à_moteur_sauf_deux_roues.png',
  '/assets/Interdictions$Interdiction_de_faire_demi_tour.png',
  '/assets/Interdictions$Interdiction_de_tourner_à_droite_à_la_prochaine_intersection.png',
  '/assets/Interdictions$Interdiction_de_tourner_à_gauche_à_la_prochaine_intersection.png',
  '/assets/Interdictions$Interdit_aux_véhicules_de_plus_de_2t_sur_un_même_essieu.png',
  '/assets/Interdictions$Interdit_aux_véhicules_de_plus_de_5_5t.png',
  '/assets/Interdictions$Limitation_de_vitesse.png',
  '/assets/Interdictions$Sens_interdit.png',
  '/assets/Interdictions$Signaux_sonores_interdits.png',
  '/assets/Interdictions$Sortie_de_zone_de_irculation_restreinte.png',
  '/assets/Interdictions$Stationnement_interdit.png',
  '/assets/Interdictions$Stationnement_interdit_du_côté_du_panneau_du_16_à_la_fin_du_mois.png',
  '/assets/Interdictions$Stationnement_interdit_du_côté_du_panneau_du_ler_au_15_du_mois.png',
  '/assets/Obligations$Chaînes_à_neige_obligatoires_sur_au_moins_2_roues_motrices.png',
  '/assets/Obligations$Chemin_obligatoire_pour_cavaliers.png',
  '/assets/Obligations$Contournement_obligatoire_de_l_obstacle_par_la_droite.png',
  '/assets/Obligations$Contournement_obligatoire_de_l_obstacle_par_la_gauche.png',
  '/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_tout_droit.png',
  '/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_tout_droit_ou_à_droite.png',
  '/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_tout_droit_ou_à_gauche.png',
  '/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_à_droite.png',
  '/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_à_gauche.png',
  '/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_à_gauche_ou_à_droite.png',
  '/assets/Obligations$Entrée_d_aire_piétonne.png',
  '/assets/Obligations$Fin_de_chemin_obligatoire_pour_cavaliers.png',
  '/assets/Obligations$Fin_de_limitation_de_vitesse.png',
  '/assets/Obligations$Fin_de_l_interdiciton_de_l_emploi_des_avertisseurs_sonores.png',
  '/assets/Obligations$Fin_de_l_obligation_d_utilisation_des_chaînes_à_neige.png',
  '/assets/Obligations$Fin_de_piste_ou_de_bande_cyclable.png',
  '/assets/Obligations$Fin_de_vitesse_minimale_obligatoire.png',
  '/assets/Obligations$Fin_de_voie_réservée_aux_véhicules_des_services_réguliers_de_transport_en_commmun.png',
  '/assets/Obligations$Fin_d_interdiction_de_dépasser.png',
  '/assets/Obligations$Fin_d_interdiction_de_dépasser_pour_les_véhicules_le_transport_de_marchandise_de_plus_de_3_5t.png',
  '/assets/Obligations$Fin_d_interdiction_dont_la_nature_est_mentionnée_sur_le_panneau.png',
  '/assets/Obligations$Fin_d_obligation_d_allumage_des_feux.png',
  '/assets/Obligations$Fin_de_toutes_les_interdiction_précedemment_signalées_et_imposées_aux_véhicules_en_mouvement.png',
  '/assets/Obligations$Obligation_de_tourner_à_droite_avant_le_panneau.png',
  '/assets/Obligations$Obligation_de_tourner_à_gauche_avant_le_panneau.png',
  '/assets/Obligations$Obligation_dont_la_nature_est_mentionnée_sur_le_panneau.png',
  '/assets/Obligations$Piste_ou_bande_obligatoire_pour_cycles_sans_side_car_à_2_ou_3_roues.png',
  '/assets/Obligations$Sortie_d_aire_piétonne.png',
  '/assets/Obligations$Vitesse_minimale_obligatoire.png',
  '/assets/Obligations$Voie_réservée_aux_tramways.png',
  '/assets/Obligations$Voie_réservée_aux_véhicules_des_services_réguliers_de_transport_en_commmun.png',
  '/assets/Obligations$_Fin_de_limitation_de_vitesse.png',
  '/assets/Services$Auberge_de_jeunesse.png',
  '/assets/Services$Cabine_téléphonique_publique.png',
  '/assets/Services$Cafétéria_ouvert_7j_7.png',
  '/assets/Services$Chambre_d_hôtes_ou_gîte.png',
  '/assets/Services$Embarcadère.png',
  '/assets/Services$Emplacement_de_mise_à_l_eau_d_embarcations_légères.png',
  '/assets/Services$Emplacement_pour_pique_nique.png',
  '/assets/Services$Gare_auto_train.png',
  '/assets/Services$Hôtel_ou_motel_ouvert_7j_7.png',
  '/assets/Services$Informations_relatives_aux_services_ou_activités_touristiques.png',
  '/assets/Services$Installations_accessibles_aux_personnes_à_mobilité_réduite.png',
  '/assets/Services$Parking_sous_surveillance.png',
  '/assets/Services$Point_de_départ_d_itinéraire_pédestre.png',
  '/assets/Services$Point_de_départ_d_un_circuit_de_ski_de_fond.png',
  '/assets/Services$Poste_de_distribution_de_carburant.png',
  '/assets/Services$Poste_de_distribution_de_carburant_assurant_le_ravitaillement_GPL.png',
  '/assets/Services$Poste_de_secours.png',
  '/assets/Services$Poste_d_appel_d_urgence.png',
  '/assets/Services$Restauration_ouvert_7j_7.png',
  '/assets/Services$Terrain_de_camping_pour_caravanes_et_auto_caravanes.png',
  '/assets/Services$Terrain_de_camping_pour_tentes.png',
  '/assets/Services$Terrain_de_camping_pour_tentes_caravanes_et_auto_caravanes.png',
  '/assets/Services$Toilettes_ouvertes_au_public.png'
]

const path_to_name = {
  "/assets/Communaux$Entrée_dans_une_agglomération.png": "Entrée dans une agglomération",
  "/assets/Communaux$Entrée_d_un_itinéraire_touristique.png": "Entrée d'un itinéraire touristique",
  "/assets/Communaux$Lieu_dit_forêt_ou_autre_lieu_traversé_par_une_route.png": "Lieu dit forêt ou autre lieu traversé par une route",
  "/assets/Communaux$Sortie_d_une_agglomération.png": "Sortie d'une agglomération",
  "/assets/Communaux$Sortie_d_un_lieu_dit.png": "Sortie d'un lieu dit",
  "/assets/Dangers$Annonce_feux_tricolores.png": "Annonce feux tricolores",
  "/assets/Dangers$Arrêt_à_l_intersection.png": "Arrêt à l'intersection",
  "/assets/Dangers$Arrêt_à_l_intersection_à_150m.png": "Arrêt à l'intersection à 150m",
  "/assets/Dangers$Barrières_à_fonctionnement_manuel.png": "Barrières à fonctionnement manuel",
  "/assets/Dangers$Carrefour_à_sens_giratoire_avec_priorité_aux_usagers_circulant_sur_l_anneau.png": "Carrefour à sens giratoire avec priorité aux usagers circulant sur l'anneau",
  "/assets/Dangers$Cassis_ou_dos_d_âne.png": "Cassis ou dos d'âne",
  "/assets/Dangers$Chaussée_rétrécie.png": "Chaussée rétrécie",
  "/assets/Dangers$Chaussée_rétrécie_par_la_droite.png": "Chaussée rétrécie par la droite",
  "/assets/Dangers$Chaussée_rétrécie_par_la_gauche.png": "Chaussée rétrécie par la gauche",
  "/assets/Dangers$Chaussé_particulièrement_glissante.png": "Chaussée particulièrement glissante",
  "/assets/Dangers$Circulation_dans_les_deux_sens.png": "Circulation dans les deux sens",
  "/assets/Dangers$Cédez_le_passage_aux_véhicules_arrivant_à_votre_droite.png": "Cédez le passage aux véhicules arrivant à votre droite",
  "/assets/Dangers$Cédez_le_passage_à_l_intersection.png": "Cédez le passage à l'intersection",
  "/assets/Dangers$Cédez_le_passage_à_l_intersection_à_150m.png": "Cédez le passage à l'intersection à 150m",
  "/assets/Dangers$Danger_permanent.png": "Danger permanent",
  "/assets/Dangers$Danger_pouvant_être_précisé_ou_non_par_un_panonceau.png": "Danger pouvant être précisé ou non par un panonceau",
  "/assets/Dangers$Danger_temporaire.png": "Danger temporaire",
  "/assets/Dangers$Descente_dangereuse.png": "Descente dangereuse",
  "/assets/Dangers$Débouché_de_cyclistes_venant_de_droite_ou_de_gauche.png": "Débouché de cyclistes venant de droite ou de gauche",
  "/assets/Dangers$Débouché_sur_un_quai_ou_une_berge.png": "Débouché sur un quai ou une berge",
  "/assets/Dangers$Endroit_fréquenté_par_les_enfants.png": "Endroit fréquenté par les enfants",
  "/assets/Dangers$Fin_de_route_prioritaire.png": "Fin de route prioritaire",
  "/assets/Dangers$Intersection_où_vous_êtes_prioritaire.png": "Intersection où vous êtes prioritaire",
  "/assets/Dangers$Passage_de_cavaliers.png": "Passage de cavaliers",
  "/assets/Dangers$Passage_d_animaux_domestiques.png": "Passage d'animaux domestiques",
  "/assets/Dangers$Passage_d_animaux_sauvages.png": "Passage d'animaux sauvages",
  "/assets/Dangers$Passage_pour_piétons.png": "Passage pour piétons",
  "/assets/Dangers$Passage_à_niveau_sans_barrière_ni_demi_barrière.png": "Passage à niveau sans barrière ni demi-barrière",
  "/assets/Dangers$Pont_mobile.png": "Pont mobile",
  "/assets/Dangers$Ralentisseur.png": "Ralentisseur",
  "/assets/Dangers$Risque_de_chut_de_pierres.png": "Risque de chute de pierres",
  "/assets/Dangers$Risque_de_fort_vent_latéral.png": "Risque de fort vent latéral",
  "/assets/Dangers$Route_prioritaire.png": "Route prioritaire",
  "/assets/Dangers$Succession_de_virages_dont_le_premier_est_à_droite.png": "Succession de virages dont le premier est à droite",
  "/assets/Dangers$Succession_de_virages_dont_le_premier_est_à_gauche.png": "Succession de virages dont le premier est à gauche",
  "/assets/Dangers$Traversée_de_voies_de_tramways.png": "Traversée de voies de tramways",
  "/assets/Dangers$Traversée_de_voies_de_transport_en_commun.png": "Traversée de voies de transport en commun",
  "/assets/Dangers$Traversée_d_une_aire_de_danger_aérien.png": "Traversée d'une aire de danger aérien",
  "/assets/Dangers$Virage_à_droite.png": "Virage à droite",
  "/assets/Dangers$Virage_à_gauche.png": "Virage à gauche",
  "/assets/Directions$Itinéraires_secondaires_ainsi_que_les_localité_les_plus_proches.png": "Itinéraires secondaires ainsi que les localités les plus proches",
  "/assets/Directions$Itinéraire_empruntant_une_autoroute.png": "Itinéraire empruntant une autoroute",
  "/assets/Directions$Itinéraire_principal_reliant_de_grandes_agglomérations_importantes.png": "Itinéraire principal reliant de grandes agglomérations importantes",
  "/assets/Indication$Annonce_d_un_créneau_de_dépassement_ou_d_une_route_2x2_voies.png": "Annonce d'un créneau de dépassement ou d'une route 2x2 voies",
  "/assets/Indication$Chaussée_à_double_sens_Sens_opposé_réservé_aux_bus.png": "Chaussée à double sens, sens opposé réservé aux bus",
  "/assets/Indication$Chaussée_à_double_sens_Sens_opposé_réservé_aux_cycles.png": "Chaussée à double sens, sens opposé réservé aux cycles",
  "/assets/Indication$Circulation_à_sens_unique.png": "Circulation à sens unique",
  "/assets/Indication$Conditions_particulières_de_circulation_sur_la_route_ou_la_voie_embranchée.png": "Conditions particulières de circulation sur la route ou la voie embranchée",
  "/assets/Indication$Conditions_particulières_de_circulation_sur_la_voie_centrale.png": "Conditions particulières de circulation sur la voie centrale",
  "/assets/Indication$Conditions_particulières_de_circulation_trois_voies_de_circulation_dans_ce_sens.png": "Conditions particulières de circulation, trois voies de circulation dans ce sens",
  "/assets/Indication$Condition_particulières_de_circulation_par_voie_en_terme_de_limitation_de_vitesse.png": "Conditions particulières de circulation par voie en termes de limitation de vitesse",
  "/assets/Indication$Créneau_de_j_dépassement_sur_section_de_route_à_trois_voies.png": "Créneau de dépassement sur section de route à trois voies",
  "/assets/Indication$Début_de_voie_verte.png": "Début de voie verte",
  "/assets/Indication$Début_d_une_section_d_autoroute.png": "Début d'une section d'autoroute",
  "/assets/Indication$Emplacement_d_arrêt_St_d_urgence.png": "Emplacement d'arrêt d'urgence",
  "/assets/Indication$Entrée_d_aire_piétonne.png": "Entrée d'aire piétonne",
  "/assets/Indication$Entrée_d_un_tunnel.png": "Entrée d'un tunnel",
  "/assets/Indication$Entrée_zone_de_rencontre.png": "Entrée zone de rencontre",
  "/assets/Indication$Fin_de_créneau_de_dépassement.png": "Fin de créneau de dépassement",
  "/assets/Indication$Fin_de_piste_ou_bande_cyclabli_conseillée_et_réservée_aux_cycles_à_2_où_3_roues.png": "Fin de piste ou bande cyclable conseillée et réservée aux cycles à 2 ou 3 roues",
  "/assets/Indication$Fin_de_route_à_accès_réglementé.png": "Fin de route à accès réglementé",
  "/assets/Indication$Fin_de_section_à_vitesse_régulée.png": "Fin de section à vitesse régulée",
  "/assets/Indication$Fin_de_vitesse_conseilée.png": "Fin de vitesse conseillée",
  "/assets/Indication$Fin_de_voie_verte.png": "Fin de voie verte",
  "/assets/Indication$Fin_d_une_section_d_autoroute.png": "Fin d'une section d'autoroute",
  "/assets/Indication$Impasse_comportant_une_issue_pour_les_cyclistes.png": "Impasse comportant une issue pour les cyclistes",
  "/assets/Indication$Impasse_comportant_une_issue_pour_les_piétons.png": "Impasse comportant une issue pour les piétons",
  "/assets/Indication$Indications_diverses.png": "Indications diverses",
  "/assets/Indication$Indication_sur_autoroute.png": "Indication sur autoroute",
  "/assets/Indication$La_voie_de_circulation_en_sens_inverse_est_réservée_aux_cyclistes.png": "La voie de circulation en sens inverse est réservée aux cyclistes",
  "/assets/Indication$Lieu_aménagé_pour_le_stationnement_le_parking_lir.png": "Lieu aménagé pour le stationnement, le parking",
  "/assets/Indication$Lieu_aménagé_pour_stationnement_gratuit_à_duré_nitée_avec_contrôle_par_disqu.png": "Lieu aménagé pour stationnement gratuit à durée limitée avec contrôle par disque",
  "/assets/Indication$Paiement_auprès_d_un_péagiste.png": "Paiement auprès d'un péagiste",
  "/assets/Indication$Paiement_automatique_par_pièces_de_monnaie.png": "Paiement automatique par pièces de monnaie",
  "/assets/Indication$Paiement_automatique_par_pièces_et_billets.png": "Paiement automatique par pièces et billets",
  "/assets/Indication$Paiement_par_abonnement.png": "Paiement par abonnement",
  "/assets/Indication$Paiement_par_carte_bancaire.png": "Paiement par carte bancaire",
  "/assets/Indication$Paiement_par_carte_bancaire_ou_accréditive.png": "Paiement par carte bancaire ou accréditive",
  "/assets/Indication$Panneau_Voie_sans_issue.png": "Voie sans issue",
  "/assets/Indication$Passage_pour_piétons.png": "Passage pour piétons",
  "/assets/Indication$Piste_ou_bande_cyclable_1_conseillée_et_réservée_aux_cycles_à_2_ou_3_roues.png": "Piste ou bande cyclable conseillée et réservée aux cycles à 2 ou 3 roues",
  "/assets/Indication$Priorité_par_rapport_à_la_circulation_venant_en_sens_inverse.png": "Priorité par rapport à la circulation venant en sens inverse",
  "/assets/Indication$Présignalisation_d_une_impasse.png": "Présignalisation d'une impasse",
  "/assets/Indication$Présignalisation_du_début_d_une_section_à_vitesse_régulée.png": "Présignalisation du début d'une section à vitesse régulée",
  "/assets/Indication$Présignalisation_d_une_borne_de_retrait_de_ticket_de_péage.png": "Présignalisation d'une borne de retrait de ticket de péage",
  "/assets/Indication$Ralentisseur_1.png": "Ralentisseur",
  "/assets/Indication$Traversée_de_voie_de_véhicule_routiers_des_services_réguliers_de_transport_en_commun.png": "Traversée de voie de véhicules routiers des services réguliers de transport en commun",
  "/assets/Indication$Route_à_accès_réglementé.png": "Route à accès réglementé",
  "/assets/Indication$Réduction_du_nombre_de_voies.png": "Réduction du nombre de voies",
  "/assets/Indication$Section_de_route_à_trois_voies_affectées.png": "Section de route à trois voies affectées",
  "/assets/Indication$Signalisation_par_voie.png": "Signalisation par voie",
  "/assets/Indication$Sortie_de_tunnel.png": "Sortie de tunnel",
  "/assets/Indication$Sortie_de_zone_de_rencontre.png": "Sortie de zone de rencontre",
  "/assets/Indication$Sortie_d_aire_piétonne.png": "Sortie d'aire piétonne",
  "/assets/Indication$Stationnement_e_payant_e.png": "Stationnement payant",
  "/assets/Indication$Stationnement_réglementé_pour_les_caravanes_et_les_autocaravanes.png": "Stationnement réglementé pour les caravanes et les autocaravanes",
  "/assets/Indication$Station_de_taxis.png": "Station de taxis",
  "/assets/Indication$Station_d_arrêt_d_autobus.png": "Station d'arrêt d'autobus",
  "/assets/Indication$Station_pour_véhicule_bénéficia_de_label_Autopartage.png": "Station pour véhicule bénéficiant du label Autopartage",
  "/assets/Indication$Traversée_de_voie_de_tramways.png": "Traversée de voie de tramways",
  "/assets/Indication$Vitesse_conseillée.png": "Vitesse conseillée",
  "/assets/Indication$Voies_affectées.png": "Voies affectées",
  "/assets/Indication$Voie_de_détresse.png": "Voie de détresse",
  "/assets/Interdictions$Acces_interdit_aux_véhicules_dont_la_hauteur_chargement_compris_dépasse_la_dimension_indiquée.png": "Accès interdit aux véhicules dont la hauteur chargement compris dépasse la dimension indiquée",
  "/assets/Interdictions$Acces_interdit_aux_véhicules_dont_la_largeur_chargement_compris_dépasse_la_dimension_indiquée.png": "Accès interdit aux véhicules dont la largeur chargement compris dépasse la dimension indiquée",
  "/assets/Interdictions$Acces_interdit_aux_véhicules_dont_la_longueur_chargement_compris_dépasse_la_dimension_indiquée.png": "Accès interdit aux véhicules dont la longueur chargement compris dépasse la dimension indiquée",
  "/assets/Interdictions$Accès_interdit_aux_cycles.png": "Accès interdit aux cycles",
  "/assets/Interdictions$Accès_interdit_aux_cyclomoteurs.png": "Accès interdit aux cyclomoteurs",
  "/assets/Interdictions$Accès_interdit_aux_motos_et_motos_légères.png": "Accès interdit aux motos et motos légères",
  "/assets/Interdictions$Accès_interdit_aux_piétons.png": "Accès interdit aux piétons",
  "/assets/Interdictions$Accès_interdit_aux_voitures_à_bras.png": "Accès interdit aux voitures à bras",
  "/assets/Interdictions$Accès_interdit_aux_véhicules_agricoles_à_moteur.png": "Accès interdit aux véhicules agricoles à moteur",
  "/assets/Interdictions$Accès_interdit_aux_véhicules_de_transport_de_marchandises_explosives_ou_facilement_inflammables.png": "Accès interdit aux véhicules de transport de marchandises explosives ou facilement inflammables",
  "/assets/Interdictions$Accès_interdit_aux_véhicules_de_transport_en_commun.png": "Accès interdit aux véhicules de transport en commun",
  "/assets/Interdictions$Accès_interdit_aux_véhicules_tractant_une_caravane_ou_remorque_de_plus_de_250kg.png": "Accès interdit aux véhicules tractant une caravane ou remorque de plus de 250kg",
  "/assets/Interdictions$Accès_interdit_aux_véhicules_transportant_des_marchandises.png": "Accès interdit aux véhicules transportant des marchandises",
  "/assets/Interdictions$Accès_interdit_aux_véhicules_transportant_des_marchandises_dangereuses.png": "Accès interdit aux véhicules transportant des marchandises dangereuses",
  "/assets/Interdictions$Accès_interdit_aux_véhicules_transportant_des_marchandises_polluant_l_eau.png": "Accès interdit aux véhicules transportant des marchandises polluant l'eau",
  "/assets/Interdictions$Accès_interdit_aux_véhicules_à_moteur.png": "Accès interdit aux véhicules à moteur",
  "/assets/Interdictions$Accès_interdit_aux_véhicules_à_moteur_sauf_cyclomoteurs.png": "Accès interdit aux véhicules à moteur sauf cyclomoteurs",
  "/assets/Interdictions$Accès_interdit_aux_véhicules_à_traction_animale.png": "Accès interdit aux véhicules à traction animale",
  "/assets/Interdictions$Arrêt_et_stationnemen_interdits.png": "Arrêt et stationnement interdits",
  "/assets/Interdictions$Arrêt_obligatoire_au_barrage_de_gendarmerie.png": "Arrêt obligatoire au barrage de gendarmerie",
  "/assets/Interdictions$Arrêt_obligatoire_au_barrage_de_police.png": "Arrêt obligatoire au barrage de police",
  "/assets/Interdictions$Arrêt_obligatoire_au_poste_de_douane.png": "Arrêt obligatoire au poste de douane",
  "/assets/Interdictions$Circulation_interdite_à_tout_véhicule_dans_les_deux_sens.png": "Circulation interdite à tout véhicule dans les deux sens",
  "/assets/Interdictions$Cédez_le_passage_à_la_circulation_venant_en_sens_inverse.png": "Cédez le passage à la circulation venant en sens inverse",
  "/assets/Interdictions$Entrée_de_zone_de_circulation_restreinte.png": "Entrée de zone de circulation restreinte",
  "/assets/Interdictions$Interdiction_de_circuler_sans_maintenir_l_invervalle_indiqué_.png": "Interdiction de circuler sans maintenir l'intervalle indiqué",
  "/assets/Interdictions$Interdiction_de_dépasser_pour_les_transports_de_marchandises_à_3_5t.png": "Interdiction de dépasser pour les transports de marchandises à 3,5t",
  "/assets/Interdictions$Interdiction_de_dépasser_tout_véhicule_à_moteur_sauf_deux_roues.png": "Interdiction de dépasser tout véhicule à moteur sauf deux roues",
  "/assets/Interdictions$Interdiction_de_faire_demi_tour.png": "Interdiction de faire demi-tour",
  "/assets/Interdictions$Interdiction_de_tourner_à_droite_à_la_prochaine_intersection.png": "Interdiction de tourner à droite à la prochaine intersection",
  "/assets/Interdictions$Interdiction_de_tourner_à_gauche_à_la_prochaine_intersection.png": "Interdiction de tourner à gauche à la prochaine intersection",
  "/assets/Interdictions$Interdit_aux_véhicules_de_plus_de_2t_sur_un_même_essieu.png": "Interdit aux véhicules de plus de 2t sur un même essieu",
  "/assets/Interdictions$Interdit_aux_véhicules_de_plus_de_5_5t.png": "Interdit aux véhicules de plus de 5,5t",
  "/assets/Interdictions$Limitation_de_vitesse.png": "Limitation de vitesse",
  "/assets/Interdictions$Sens_interdit.png": "Sens interdit",
  "/assets/Interdictions$Signaux_sonores_interdits.png": "Signaux sonores interdits",
  "/assets/Interdictions$Sortie_de_zone_de_irculation_restreinte.png": "Sortie de zone de circulation restreinte",
  "/assets/Interdictions$Stationnement_interdit.png": "Stationnement interdit",
  "/assets/Interdictions$Stationnement_interdit_du_côté_du_panneau_du_16_à_la_fin_du_mois.png": "Stationnement interdit du côté du panneau du 16 à la fin du mois",
  "/assets/Interdictions$Stationnement_interdit_du_côté_du_panneau_du_ler_au_15_du_mois.png": "Stationnement interdit du côté du panneau du 1er au 15 du mois",
  "/assets/Obligations$Chaînes_à_neige_obligatoires_sur_au_moins_2_roues_motrices.png": "Chaînes à neige obligatoires sur au moins 2 roues motrices",
  "/assets/Obligations$Chemin_obligatoire_pour_cavaliers.png": "Chemin obligatoire pour cavaliers",
  "/assets/Obligations$Contournement_obligatoire_de_l_obstacle_par_la_droite.png": "Contournement obligatoire de l'obstacle par la droite",
  "/assets/Obligations$Contournement_obligatoire_de_l_obstacle_par_la_gauche.png": "Contournement obligatoire de l'obstacle par la gauche",
  "/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_tout_droit.png": "Direction obligatoire à la prochaine intersection tout droit",
  "/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_tout_droit_ou_à_droite.png": "Direction obligatoire à la prochaine intersection tout droit ou à droite",
  "/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_tout_droit_ou_à_gauche.png": "Direction obligatoire à la prochaine intersection tout droit ou à gauche",
  "/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_à_droite.png": "Direction obligatoire à la prochaine intersection à droite",
  "/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_à_gauche.png": "Direction obligatoire à la prochaine intersection à gauche",
  "/assets/Obligations$Direction_obligatoire_à_la_prochaine_intersection_à_gauche_ou_à_droite.png": "Direction obligatoire à la prochaine intersection à gauche ou à droite",
  "/assets/Obligations$Entrée_d_aire_piétonne.png": "Entrée d'aire piétonne",
  "/assets/Obligations$Fin_de_chemin_obligatoire_pour_cavaliers.png": "Fin de chemin obligatoire pour cavaliers",
  "/assets/Obligations$Fin_de_limitation_de_vitesse.png": "Fin de limitation de vitesse",
  "/assets/Obligations$Fin_de_l_interdiciton_de_l_emploi_des_avertisseurs_sonores.png": "Fin de l'interdiction de l'emploi des avertisseurs sonores",
  "/assets/Obligations$Fin_de_l_obligation_d_utilisation_des_chaînes_à_neige.png": "Fin de l'obligation d'utilisation des chaînes à neige",
  "/assets/Obligations$Fin_de_piste_ou_de_bande_cyclable.png": "Fin de piste ou de bande cyclable",
  "/assets/Obligations$Fin_de_vitesse_minimale_obligatoire.png": "Fin de vitesse minimale obligatoire",
  "/assets/Obligations$Fin_de_voie_réservée_aux_véhicules_des_services_réguliers_de_transport_en_commmun.png": "Fin de voie réservée aux véhicules des services réguliers de transport en commun",
  "/assets/Obligations$Fin_d_interdiction_de_dépasser.png": "Fin d'interdiction de dépasser",
  "/assets/Obligations$Fin_d_interdiction_de_dépasser_pour_les_véhicules_le_transport_de_marchandise_de_plus_de_3_5t.png": "Fin d'interdiction de dépasser pour les véhicules transportant des marchandises de plus de 3,5t",
  "/assets/Obligations$Fin_d_interdiction_dont_la_nature_est_mentionnée_sur_le_panneau.png": "Fin d'interdiction dont la nature est mentionnée sur le panneau",
  "/assets/Obligations$Fin_d_obligation_d_allumage_des_feux.png": "Fin de l'obligation d'allumage des feux",
  "/assets/Obligations$Fin_de_toutes_les_interdiction_précedemment_signalées_et_imposées_aux_véhicules_en_mouvement.png": "Fin de toutes les interdictions précédemment signalées et imposées aux véhicules en mouvement",
  "/assets/Obligations$Obligation_de_tourner_à_droite_avant_le_panneau.png": "Obligation de tourner à droite avant le panneau",
  "/assets/Obligations$Obligation_de_tourner_à_gauche_avant_le_panneau.png": "Obligation de tourner à gauche avant le panneau",
  "/assets/Obligations$Obligation_dont_la_nature_est_mentionnée_sur_le_panneau.png": "Obligation dont la nature est mentionnée sur le panneau",
  "/assets/Obligations$Piste_ou_bande_obligatoire_pour_cycles_sans_side_car_à_2_ou_3_roues.png": "Piste ou bande obligatoire pour cycles sans side-car à 2 ou 3 roues",
  "/assets/Obligations$Sortie_d_aire_piétonne.png": "Sortie d'aire piétonne",
  "/assets/Obligations$Vitesse_minimale_obligatoire.png": "Vitesse minimale obligatoire",
  "/assets/Obligations$Voie_réservée_aux_tramways.png": "Voie réservée aux tramways",
  "/assets/Obligations$Voie_réservée_aux_véhicules_des_services_réguliers_de_transport_en_commmun.png": "Voie réservée aux véhicules des services réguliers de transport en commun",
  "/assets/Obligations$_Fin_de_limitation_de_vitesse.png": "Fin de limitation de vitesse",
  "/assets/Services$Auberge_de_jeunesse.png": "Auberge de jeunesse",
  "/assets/Services$Cabine_téléphonique_publique.png": "Cabine téléphonique publique",
  "/assets/Services$Cafétéria_ouvert_7j_7.png": "Cafétéria ouverte 7j/7",
  "/assets/Services$Chambre_d_hôtes_ou_gîte.png": "Chambre d'hôtes ou gîte",
  "/assets/Services$Embarcadère.png": "Embarcadère",
  "/assets/Services$Emplacement_de_mise_à_l_eau_d_embarcations_légères.png": "Emplacement de mise à l'eau d'embarcations légères",
  "/assets/Services$Emplacement_pour_pique_nique.png": "Emplacement pour pique-nique",
  "/assets/Services$Gare_auto_train.png": "Gare auto-train",
  "/assets/Services$Hôtel_ou_motel_ouvert_7j_7.png": "Hôtel ou motel ouvert 7j/7",
  "/assets/Services$Informations_relatives_aux_services_ou_activités_touristiques.png": "Informations relatives aux services ou activités touristiques",
  "/assets/Services$Installations_accessibles_aux_personnes_à_mobilité_réduite.png": "Installations accessibles aux personnes à mobilité réduite",
  "/assets/Services$Parking_sous_surveillance.png": "Parking sous surveillance",
  "/assets/Services$Point_de_départ_d_itinéraire_pédestre.png": "Point de départ d'itinéraire pédestre",
  "/assets/Services$Point_de_départ_d_un_circuit_de_ski_de_fond.png": "Point de départ d'un circuit de ski de fond",
  "/assets/Services$Poste_de_distribution_de_carburant.png": "Poste de distribution de carburant",
  "/assets/Services$Poste_de_distribution_de_carburant_assurant_le_ravitaillement_GPL.png": "Poste de distribution de carburant assurant le ravitaillement GPL",
  "/assets/Services$Poste_de_secours.png": "Poste de secours",
  "/assets/Services$Poste_d_appel_d_urgence.png": "Poste d'appel d'urgence",
  "/assets/Services$Restauration_ouvert_7j_7.png": "Restauration ouverte 7j/7",
  "/assets/Services$Terrain_de_camping_pour_caravanes_et_auto_caravanes.png": "Terrain de camping pour caravanes et autocaravanes",
  "/assets/Services$Terrain_de_camping_pour_tentes.png": "Terrain de camping pour tentes",
  "/assets/Services$Terrain_de_camping_pour_tentes_caravanes_et_auto_caravanes.png": "Terrain de camping pour tentes, caravanes et autocaravanes",
  "/assets/Services$Toilettes_ouvertes_au_public.png": "Toilettes ouvertes au public"
};


function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function get_similarity(answer) {
  var answer_words = answer.replace("/assets/", "").replace("$", " ").replaceAll("_", " ").toLowerCase().split(" ")
  var remove_text = panels.map(person => person.replace("/assets/", "").replace("$", " ").replaceAll("_", " ").toLowerCase());

  var similarity_scores = remove_text.map((text, index) => {
    var text_words = text.split(" ");
    var match_count = text_words.filter(word => answer_words.includes(word)).length;
    return { text: panels[index], match_count: match_count };
  });

  similarity_scores = similarity_scores.filter(item => {
    return item.text !== answer;
  });


  similarity_scores.sort((a, b) => b.match_count - a.match_count);

  var top_three = similarity_scores.slice(0, 3).map(item => item.text);
  console.log(top_three, answer)
  return shuffleArray([...top_three, answer])

}

function Answer(props){
  const [has_submit, set_has_submit] = useState(false)

  const onrep = () => {
    if(!props.show_error){
      set_has_submit(true)
    }
    props.submit()
  }
  useEffect(()=>{
    set_has_submit(false)
  },[props.text])
  return(
    <div onClick={()=>{onrep()}} style={{minWidth:"30%", userSelect:"none", color:(props.show_error&&has_submit)||(props.show_error&&props.correct)?"white":"black", textAlign:"center",fontSize:13, cursor:"pointer", padding:12, backgroundColor:!props.show_error?"rgba(0,0,0,0.08)":props.correct?"#5AA469":has_submit?"#D35D6E":"rgba(0,0,0,0.08)", borderRadius:5}}>{props.text.split("$")[0].replace("/assets/", "")} - {path_to_name[props.text]}</div>
  )
}


function App() {
  const [current_question, set_current_question] = useState(0)
  const [correct, set_correct] = useState(0)
  const [possible_answer, set_possible_answer] = useState([])
  const [show_error, set_show_error] = useState(false)
  const loading_question = useRef(false)
  const [answered_question, set_answered_question] = useState(0)
  const [mode, set_mode] = useState(0)
  const already_done = useRef([])
  const init_run = useRef(false)
  const [finito, set_finito] = useState(false)
  const [do_error, set_doerror] = useState(false)

  const re_draw_question = () => {
    let new_current = panels[Math.floor(panels.length * Math.random())]
    if(mode == 1){
      if(already_done.current.length == 203){
        if(correct == 203){
          set_finito(true)
        }
        already_done.current = []
      }else{
        let temp = panels.filter(panel => !already_done.current.includes(panel));
        new_current = temp[Math.floor(Math.random() * temp.length)];
      }
    }
    already_done.current.push(new_current)
    set_doerror(false)
    set_show_error(false)
    set_current_question(new_current)
    set_possible_answer(get_similarity(new_current))
  }

  const change_mode = () => {
    if(mode == 0){
      set_mode(1)
    }else{
      set_mode(0)
    }
    set_correct(0)
    already_done.current = []
    set_answered_question(0)
    set_doerror(false)
  }

  const load_new_question = () => {
    re_draw_question()
    set_answered_question(answered_question+1)
    loading_question.current = false
  }

  const submit_answer = (index) => {
    let has_doing_error = false
    if(!loading_question.current){
      loading_question.current = true
      if(mode == 0){
        if(possible_answer[index] == current_question){
          if(correct == 19){
            set_finito(true)
          }
          set_correct(correct=>correct+1)
        }else{
          set_correct(correct=>correct-4)
          set_doerror(true)
          has_doing_error = true
        }
      }else{
        if(possible_answer[index] == current_question){
          set_correct(correct=>correct+1)
        }else{
          set_doerror(true)
          has_doing_error = true
        }
      }
      set_show_error(true)
      if(!has_doing_error){
        setTimeout(() => {
          load_new_question()
        }, 1000);
      }
    }
  }

  useEffect(()=>{
    if(init_run.current == false){
      init_run.current = true
      re_draw_question()
    }
  }, [])
  return (
    <div style={{userSelect:"none",flexDirection:"column", height:"100vh", gap:30, width:"100%", justifyContent:"center", alignItems:"center"}}>
        <div style={{position:"absolute", top:"1em", left:"1em", right:0}}>
          {mode==1&&<span style={{fontSize:20}}>Score:{correct}/{panels.length}</span>}
          {mode==0&&<span style={{fontSize:20}}>Score:{correct}/20</span>}
          {finito&&<span>bv bg t'as reussi ! tu te sens fier ?</span>}
          <span style={{fontSize:20}}>Question répondu:{answered_question}</span>
          <button onClick={()=>{change_mode()}} style={{width:"fit-content"}}>change mode</button>
        </div>
        <div style={{alignItems:"center", width:"100%", maxWidth:"300px"}}>
          <img src={current_question} style={{width: '50%'}} />
        </div>
        
        <div style={{gap:10, maxWidth:"90%"}}>
          {possible_answer.map((item, index) => {
            return (
              <Answer 
                key={index} 
                text={item}
                show_error={show_error}
                correct={item==current_question} 
                submit={() => {submit_answer(index)}}  
              />
            );
          })}
        </div>
        {do_error&&<button onClick={()=>{load_new_question()}} style={{backgroundColor:"rgba(0,0,0,0.08)", border:"1px solid rgba(0,0,0,0.08)", padding:10, borderRadius:4}}>J'ai constaté mon échec -></button>}
    </div>
  );
}

export default App;